import React, { useState } from "react"
import { clientAuth } from "../graphql"
import gql from "graphql-tag"
import moment from "moment"
import { dispathLoginEvent } from "./chromeExtension"

export const AuthContext = React.createContext(null)

const authStorageKey = "auth-v1"

const authDefaults = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
    role: "anonymous",
    displayName: "",
    accountID: -1,
  },
  authToken: undefined,
  refreshToken: undefined,
}

const savePersistence = ({ user, authToken, refreshToken }) => {
  localStorage.setItem(authStorageKey, JSON.stringify({ user, authToken, refreshToken }))
}

const loadPersistence = () => {
  let state
  try {
    state = JSON.parse(localStorage.getItem(authStorageKey))
  } catch (e) {
    console.error(e)
  }
  if (!state) state = { ...authDefaults }
  return state
}

const staffURL = typeof window !== "undefined" ? "//" + window.location.host.replace(/^[a-z0-9-]*app\./, "staff.") : null

const apollo = clientAuth()

export const AuthProvider = ({ children }) => {
  const persistence = loadPersistence()
  const [auth, setAuth] = useState(persistence)

  const handleLoginSuccess = ({ me, auth, refresh, onetime, onSuccess }) => {
    if (me.role === "staff") {
      window.location.href = staffURL + "/login/token/" + onetime.token
    } else {
      const authData = { user: me, authToken: auth, refreshToken: refresh }
      if (!!onSuccess) {
        onSuccess(authData)
      }
      savePersistence(authData)
      setAuth(authData)
      dispathLoginEvent()
    }
  }

  const handleLoginError = ({ error, onError }) => {
    if (!!onError) {
      onError(error)
    }
  }

  const loginPassword = ({ email, password, onSuccess, onError }) => {
    return apollo
      .mutate({ mutation: LOGIN_PASSWORD, variables: { email, password } })
      .then(
        ({
          data: {
            login: { me, auth, refresh, onetime },
          },
        }) => {
          handleLoginSuccess({ me, auth, refresh, onetime, onSuccess })
        },
      )
      .catch((error) => {
        handleLoginError({ error, onError })
      })
  }

  const loginToken = ({ token, onSuccess, onError }) => {
    return apollo
      .mutate({ mutation: LOGIN_TOKEN, variables: { token } })
      .then(
        ({
          data: {
            login: { me, auth, refresh, onetime },
          },
        }) => {
          handleLoginSuccess({ me, auth, refresh, onetime, onSuccess })
        },
      )
      .catch((error) => {
        handleLoginError({ error, onError })
      })
  }

  const confirmAccount = ({ token, onSuccess, onError }) => {
    return apollo
      .mutate({ mutation: CONFIRM_ACCOUNT, variables: { token }})
      .then(
        ({
          data: {
            confirmAccount: { me, auth, refresh, onetime },
          },
        }) => {
          handleLoginSuccess({ me, auth, refresh, onetime, onSuccess })
        },)
      .catch((error) => {
        handleLoginError({ error, onError })
      })
  }

  const refresh = () => {
    if (!!auth.refreshToken) {
      const usable = moment(auth.refreshToken.notBefore).isBefore(moment())
      if (usable) {
        apollo
          .mutate({ mutation: REFRESH, variables: { token: auth.refreshToken.token } })
          .then(
            ({
              data: {
                refresh: { me, auth, refresh },
              },
            }) => {
              setAuth({ user: me, authToken: auth, refreshToken: refresh })
              savePersistence({ user: me, authToken: auth, refreshToken: refresh })
            },
          )
          .catch(({ graphQLErrors }) => {
            console.log(graphQLErrors)
            if (Array.isArray(graphQLErrors) && graphQLErrors.length && graphQLErrors[0].message.includes("token has expired") !== -1) {
              logout()
            }
          })
      }
    }
  }

  const setPassword = (password, token) => {
    return apollo.mutate({ mutation: SET_PASSWORD, variables: { input: { password, token } } }).then(({ data: { setPassword } }) => {
      const { me, auth, refresh, onetime } = setPassword
      setAuth({ user: me, authToken: auth, refreshToken: refresh })
      savePersistence({ user: me, authToken: auth, refreshToken: refresh })
      dispathLoginEvent()

      if (me.role === "staff") {
        window.location.href = staffURL + "/loginEmailPassword/token/" + onetime.token
      }
    })
  }

  const logout = () => {
    savePersistence({ ...authDefaults })
    setAuth({ ...authDefaults })
  }

  const ctxValue = {
    loginPassword,
    confirmAccount,
    loginToken,
    logout,
    refresh,
    setPassword,
    role: auth.user.role,
    ...auth,
  }

  return <AuthContext.Provider value={ctxValue}>{children}</AuthContext.Provider>
}

const LOGIN_PASSWORD = gql`
  mutation Login($email: String!, $password: String!) {
    login: loginPassword(input: { email: $email, password: $password }) {
      me {
        firstName
        lastName
        displayName
        email
        role
        accountID
      }
      auth {
        token
        notBefore
        expiresAt
      }
      refresh {
        token
        notBefore
        expiresAt
      }
      onetime {
        token
        notBefore
        expiresAt
      }
    }
  }
`

const LOGIN_TOKEN = gql`
  mutation Login($token: String!) {
    login: loginToken(input: { token: $token }) {
      me {
        firstName
        lastName
        displayName
        email
        role
        accountID
      }
      auth {
        token
        notBefore
        expiresAt
      }
      refresh {
        token
        notBefore
        expiresAt
      }
      onetime {
        token
        notBefore
        expiresAt
      }
    }
  }
`

const CONFIRM_ACCOUNT = gql`
  mutation ConfirmAccount($token: String!) {
    confirmAccount(token: $token) {
      me {
        firstName
        lastName
        displayName
        email
        role
        accountID
      }
      auth {
        token
        notBefore
        expiresAt
      }
      refresh {
        token
        notBefore
        expiresAt
      }
      onetime {
        token
        notBefore
        expiresAt
      }
    }
  }
`

const REFRESH = gql`
  mutation Refresh($token: String!) {
    refresh(input: { token: $token }) {
      me {
        firstName
        lastName
        displayName
        email
        role
        accountID
      }
      auth {
        token
        notBefore
        expiresAt
      }
      refresh {
        token
        notBefore
        expiresAt
      }
    }
  }
`

const SET_PASSWORD = gql`
  mutation SetPassword($input: SetPasswordInput!) {
    setPassword(input: $input) {
      me {
        firstName
        lastName
        displayName
        email
        role
        accountID
      }
      auth {
        token
        notBefore
        expiresAt
      }
      refresh {
        token
        notBefore
        expiresAt
      }
      onetime {
        token
        notBefore
        expiresAt
      }
    }
  }
`
