import React from 'react'
import ReactDOM from 'react-dom'
import "typeface-montserrat"
import "typeface-roboto-mono"
import App from './App'

import * as serviceWorker from './serviceWorker'
import { AuthProvider } from './components/auth'

ReactDOM.render(
  <AuthProvider>
    <App/>
  </AuthProvider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
