import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, from, split } from '@apollo/client'
import { WebSocketLink } from "@apollo/client/link/ws";
import { RetryLink } from "@apollo/client/link/retry";
import { onError as onApolloError } from "@apollo/client/link/error";
import fetch from "isomorphic-fetch"
import { getAPIUrl, getWsAPIUrl } from "./config"
import { getMainDefinition } from "@apollo/client/utilities"

const GRAPHQL_URL = getAPIUrl()

const apolloClient = ({ path, token, onError, withWebSockets }) => {
  const httpLink = new HttpLink({
    uri: GRAPHQL_URL + `/${path}/`,
    fetch,
  })

  const wsLink = new WebSocketLink({
    uri: `${getWsAPIUrl()}/${path}/`,
    timeout: 30000,
    options: {
      lazy: true,
      reconnect: true,
      reconnectionAttempts: 10,
      connectionParams: () => ({
        token: token,
      }),
    },
  })

  const authLink = new ApolloLink((operation, forward) => {
    if (!!token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    }
    return forward(operation)
  })

  const errorLink = onApolloError(({ networkError }) => {
    if (!!networkError && networkError.statusCode !== 422) {
      onError()
    }
  });

  const protoLink = !withWebSockets
    ? httpLink
    : split(
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query)
          return kind === "OperationDefinition" && operation === "subscription"
        },
        wsLink,
        httpLink,
      )

  const retryLink = new RetryLink({ attempts: {max: 2}});
  const link = from([authLink, retryLink, protoLink, errorLink])
  const cache = new InMemoryCache()
  return new ApolloClient({ link, cache })
}

export const clientAuth = () => {
  return apolloClient({ onError: console.error, path: "_graphql/auth", withWebSockets: false })
}

export const clientCustomers = ({ token, onError }) => {
  return apolloClient({ token, onError, path: "_graphql/customers", withWebSockets: true })
}

export const clientPartners = ({ token, onError }) => {
  return apolloClient({ token, onError, path: "_graphql/partners", withWebSockets: false })
}
