import React, { Suspense, useContext, lazy } from "react"
import { Modal, Spinner } from "react-bootstrap"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"

import "bootstrap/dist/css/bootstrap.css"
import "react-bootstrap-typeahead/css/Typeahead.css"
import "./assets/css/dashboard.css"
import "./assets/css/dashboard-style.css"
import "./assets/css/override-dashboard.css"
import "./assets/css/override-bootstrap.css"
import { AuthContext } from "./components/auth"

const Auth = lazy(() => import(/* webpackPrefetch: true */ "./pages/auth"))
const Customers = lazy(() => import(/* webpackPrefetch: true */ "./pages/customers"))
const Partners = lazy(() => import(/* webpackPrefetch: true */ "./pages/partners"))
const Error = lazy(() => import(/* webpackPrefetch: true */ "./pages/error"))

const suspenseFallback = (
  <Modal show={true} centered={true} animation={false} backdrop={false} keyboard={false} className="modal-transparent">
    <Modal.Body>
      <div className="text-center">
        <Spinner animation="border" variant="primary"/>
      </div>
    </Modal.Body>
  </Modal>
)

const App = () => {
  const { role } = useContext(AuthContext)

  return (
    <BrowserRouter>
      <Suspense fallback={suspenseFallback}>
        <Switch>
          <Route path="/auth" ><Auth /></Route>
          {role === "anonymous" ? <Redirect path="/" to="/auth/login"/> : null}

          <Route path="/customers" ><Customers /></Route>
          <Route path="/partners" ><Partners /> </Route>

          {role === "customer" ? <Redirect path="/" exact to="/customers"/> : null}
          {role === "partner" ? <Redirect path="/" exact to="/partners"/> : null}

          <Route path="/" ><Error/></Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
