const grapqlUrl = process.env.REACT_APP_GRAPHQL_URL

export const getAPIUrl = () => {
  return grapqlUrl ? grapqlUrl : typeof window !== "undefined" ? window.location.host.replace(/^app\./, "//gql.") : null
}

export const getWsAPIUrl = () => {
  const protocol = window.location.protocol === "http:" ? "ws" : "wss"
  return grapqlUrl
    ? `${protocol}://${grapqlUrl}`
    : typeof window !== "undefined"
    ? window.location.host.replace(/^app\./, `${protocol}://gql.`)
    : null
}

const spiderID = `cdcfe5cc-a7c3-4e98-9922-26c652b5d10f`
const gatewayProviderURL = `https://gateway.app.datamynt.com`

export const getCryptoGatewayURL = ({ firstName, lastName, email, amountUSD }) => {
  const baseURL = new URL(`${gatewayProviderURL}/crypto-payment/${spiderID}`);
  baseURL.searchParams.append("firstName", firstName);
  baseURL.searchParams.append("lastName", lastName);
  baseURL.searchParams.append("email", email);
  baseURL.searchParams.append("totalAmount", amountUSD);
  return baseURL.href;
}
